import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SnackBar from "../../components/Snackbar/SnackBar";
import {
  editAtendimento,
  editMemorial,
  getToken,
  formatToRecifeTimezone,
} from "./services";
import { AtendimentoRequest } from "../../models/createAtendimentoRequest";
import { MemorialRequest } from "../../models/memorialRequest";
import { useParams } from "react-router-dom";
import { EditMemorialForm } from "../../components/EditMemorialForm/EditMemorialForm";
import { useEditMemorialForm } from "../../hooks/useEditMemorialForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function EditMemorial() {
  let { id } = useParams<{ id?: string }>();
  const classes = useStyles();
  const [token, setToken] = useState<string>("");
  const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isSnackError, setSnackError] = useState<boolean>(false);

  const {
    formData,
    errors,
    isLoading,
    setIsLoading,
    updateField,
    validateForm,
  } = useEditMemorialForm(id);

  const sendGetTokenRequest = async () => {
    let getTokenRequest = await getToken();
    if (getTokenRequest.res && getTokenRequest.data.token !== undefined) {
      setToken("Bearer " + getTokenRequest.data.token);
    }
  };

  const formatDate = (dateString: string) => {
    var dateComponents = dateString.split("/");
    var day = parseInt(dateComponents[0]);
    var month = parseInt(dateComponents[1]);
    var year = parseInt(dateComponents[2]);

    const dateFormatted = new Date(year, month - 1, day)
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    return dateFormatted;
  };

  const sendEditAtendimentoRequest = async () => {
    const data: AtendimentoRequest = {
      falecidomemorialativo: 1,
      falecidocpf: formData.deceased.cpf,
      falecidonome: formData.deceased.name,
      falecidodatanascimento: formatDate(formData.deceased.birthday),
      falecidodataobito: formatDate(formData.deceased.deathday),
      memorialresponsavelnome: "",
      memorialresponsavelemail: "",
    };

    let editAtendimentoRequest = await editAtendimento(data);

    if (
      editAtendimentoRequest.res &&
      editAtendimentoRequest.data !== undefined
    ) {
      return { success: true };
    } else {
      return {
        success: false,
        error: editAtendimentoRequest.errorData,
      };
    }
  };

  const sendEditMemorialRequest = async () => {
    if (id !== undefined) {
      const data: MemorialRequest = {
        token: token,
        data: {
          id: parseInt(id),
          cpf: formData.deceased.cpf,
          name: formData.deceased.name,
          description: formData.deceased.description,
          birthdayDateText: formatDate(formData.deceased.birthday),
          deathDateText: formatDate(formData.deceased.deathday),
          funeralLocation: formData.funeral.location,
          funeralCity: formData.funeral.city,
          funeralState: formData.funeral.state,
          funeralStartDateText:
            formData.funeral.startDate !== "" ||
            formData.funeral.startTime !== ""
              ? formatToRecifeTimezone(
                  formData.funeral.startDate,
                  formData.funeral.startTime
                )
              : "",
          funeralEndDateText:
            formData.funeral.endDate !== "" || formData.funeral.endTime !== ""
              ? formatToRecifeTimezone(
                  formData.funeral.endDate,
                  formData.funeral.endTime
                )
              : "",
          burialLocation: formData.burial.location,
          burialCity: formData.burial.city,
          burialState: formData.burial.state,
          burialStartDateText:
            formData.burial.startDate !== "" || formData.burial.startTime !== ""
              ? formatToRecifeTimezone(
                  formData.burial.startDate,
                  formData.burial.startTime
                )
              : "",
          burialEndDateText:
            formData.burial.endDate !== "" || formData.burial.endTime !== ""
              ? formatToRecifeTimezone(
                  formData.burial.endDate,
                  formData.burial.endTime
                )
              : "",
          activeTribute: formData.deceased.isTribute,
          cremation: formData.deceased.isCremation,
          hasBurialTime: formData.burial.hasTime ? 1 : 0,
          hasFuneralTime: formData.funeral.hasTime ? 1 : 0,
        },
      };

      let editMemorialRequest = await editMemorial(data);
      if (editMemorialRequest.res && editMemorialRequest.data !== undefined) {
        return { success: true };
      } else {
        return {
          success: false,
          error: editMemorialRequest.errorData,
        };
      }
    } else {
      return {
        success: false,
        error: "ID do memorial não definido",
      };
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        const memorialRequest = await sendEditMemorialRequest();
        if (memorialRequest.success) {
          const atendimentoRequest = await sendEditAtendimentoRequest();
          if (atendimentoRequest.success) {
            setSnackMessage("Memorial atualizado com sucesso.");
            setSnackError(false);
            setSnackOpen(true);
          } else {
            setSnackMessage(
              atendimentoRequest.error
                ? `Erro ao atualizar atendimento: ${atendimentoRequest.error}`
                : "Erro ao atualizar atendimento, tente novamente"
            );
            setSnackError(true);
            setSnackOpen(true);
          }
        } else {
          setSnackMessage(
            memorialRequest.error
              ? `Erro ao atualizar memorial: ${memorialRequest.error}`
              : "Erro ao atualizar memorial, tente novamente"
          );
          setSnackError(true);
          setSnackOpen(true);
        }
      } catch (error) {
        console.error("Erro ao atualizar memorial:", error);
        setSnackMessage("Erro ao atualizar memorial, tente novamente");
        setSnackError(true);
        setSnackOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    sendGetTokenRequest();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <PeopleAltIcon />
        <Typography component="h1" variant="h5">
          Editar Atendimento
        </Typography>
        <EditMemorialForm
          formData={formData}
          errors={errors}
          isLoading={isLoading}
          onFieldChange={updateField}
          onSubmit={handleSubmit}
        />
      </div>
      <SnackBar
        message={snackMessage}
        isOpen={isSnackOpen}
        severity={isSnackError ? "error" : "success"}
        handleClose={() => setSnackOpen(false)}
      />
    </Container>
  );
}
