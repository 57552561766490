import React, { useState, useEffect } from "react";
import {
  Container,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CssBaseline from "@material-ui/core/CssBaseline";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { deleteUser, listUsers, updateUser } from "./services";
import { Dialog } from "../../components/Dialog/Dialog";
import { CustomModal } from "../../components/Modal/CustomModal";
import SnackBar from "../../components/Snackbar/SnackBar";

export interface User {
  username: string;
  email: string;
  id: number;
  name: string;
}

export interface UserUpdate {
  password: string;
  id: number | undefined;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  table: {
    width: "100%",
  },
  progress: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  noData: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  helperText: {
    marginLeft: 0,
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.selected,
      },
    },
  })
)(TableRow);

export default function ListUsers() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [openDialogApproval, setOpenDialogApproval] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number | undefined>(
    undefined
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isSnackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setSnackError] = useState(false);

  const loadUsers = async () => {
    setIsLoading(true);
    try {
      const response = await listUsers();
      if (response.res) {
        setUsersList(response.data);
      }
    } catch (error: any) {
      setSnackMessage(
        error.response?.data?.message
          ? `Erro ao carregar lista de usuários: ${error.response.data.message}`
          : "Erro ao carregar lista de usuários"
      );
      setSnackError(true);
      setSnackOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    setIsLoading(true);
    try {
      const response = await deleteUser(selectedUser);
      if (response.res) {
        setSnackMessage("Usuário excluído com sucesso");
        setSnackError(false);
        setSnackOpen(true);
        loadUsers();
      } else {
        setSnackMessage(
          response.errorData
            ? `Erro ao excluir usuário: ${response.errorData}`
            : "Erro ao excluir usuário"
        );
        setSnackError(true);
        setSnackOpen(true);
      }
    } catch (error: any) {
      setSnackMessage(
        error.response?.data?.message
          ? `Erro ao excluir usuário: ${error.response.data.message}`
          : "Erro ao excluir usuário"
      );
      setSnackError(true);
      setSnackOpen(true);
    } finally {
      setIsLoading(false);
      setOpenDialogApproval(false);
    }
  };

  const handleUpdateUser = async () => {
    if (confirmPassword !== password) {
      setConfirmPasswordError("As senhas devem ser iguais");
      return;
    }

    setIsLoading(true);
    try {
      const response = await updateUser({ id: selectedUser, password });
      if (response.res) {
        setSnackMessage("Senha atualizada com sucesso");
        setSnackError(false);
        setSnackOpen(true);
        setPassword("");
        setConfirmPassword("");
      } else {
        setSnackMessage(
          response.errorData
            ? `Erro ao atualizar usuário: ${response.errorData}`
            : "Erro ao atualizar usuário"
        );
        setSnackError(true);
        setSnackOpen(true);
      }
    } catch (error: any) {
      setSnackMessage(
        error.response?.data?.message
          ? `Erro ao atualizar usuário: ${error.response.data.message}`
          : "Erro ao atualizar usuário"
      );
      setSnackError(true);
      setSnackOpen(true);
    } finally {
      setIsLoading(false);
      setOpenEditModal(false);
    }
  };

  const handleChangePassword = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPassword(event.target.value as string);
    setPasswordError("");
  };

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setConfirmPassword(event.target.value as string);
    setConfirmPasswordError("");
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <PeopleAltIcon className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Lista de Usuários
        </Typography>

        {isLoading && <LinearProgress className={classes.progress} />}

        <Dialog
          openDialog={openDialogApproval}
          setOpenDialog={setOpenDialogApproval}
          dialogTitle="Tem certeza que deseja excluir esse usuário?"
          primaryButtonText="Confirmar"
          onClickPrimaryButton={handleDeleteUser}
          secondaryButtonText="Cancelar"
          onClickSecondaryButton={() => setOpenDialogApproval(false)}
        />

        <CustomModal
          dialogTitle="Editar usuário selecionado"
          openDialog={openEditModal}
          setOpenDialog={setOpenEditModal}
          primaryButtonText="Confirmar"
          onClickPrimaryButton={handleUpdateUser}
          secondaryButtonText="Cancelar"
          onClickSecondaryButton={() => setOpenEditModal(false)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                fullWidth
                label="Nova Senha"
                name="password"
                autoComplete="password"
                type="password"
                id="password"
                onChange={handleChangePassword}
                value={password}
                error={!!passwordError}
                helperText={passwordError}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                fullWidth
                label="Confirmar Nova Senha"
                name="confirmPassword"
                autoComplete="password"
                type="password"
                id="confirm-password"
                onChange={handleChangeConfirmPassword}
                value={confirmPassword}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
          </Grid>
        </CustomModal>

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell align="left">Usuário</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="center" width="120px">
                  Ações
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersList.length > 0 ? (
                usersList.map((user) => (
                  <StyledTableRow key={user.id}>
                    <StyledTableCell component="th" scope="row">
                      {user.id}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {user.username}
                    </StyledTableCell>
                    <StyledTableCell align="left">{user.email}</StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        onClick={() => {
                          setOpenEditModal(true);
                          setSelectedUser(user.id);
                        }}
                        color="primary"
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setOpenDialogApproval(true);
                          setSelectedUser(user.id);
                        }}
                        style={{ color: "#B03A37" }}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className={classes.noData}>
                    {isLoading
                      ? "Carregando usuários..."
                      : "Nenhum usuário encontrado"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <SnackBar
        message={snackMessage}
        isOpen={isSnackOpen}
        severity={isSnackError ? "error" : "success"}
        handleClose={() => setSnackOpen(false)}
      />
    </Container>
  );
}
