import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo-memorial-vila.png";
import { signIn } from "./services";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  alert: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean | undefined>(false);

  const handleClick = async () => {
    setShowAlert(undefined);
    let signInRequest = await signIn(userName, password);
    if (signInRequest.res) {
      const sessionData = {
        ...signInRequest.data,
        loginTime: new Date().getTime(),
      };
      sessionStorage.setItem("iv-crm", JSON.stringify(sessionData));
      history.push("/home");
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          alt="logo"
          src={logo}
          style={{ width: "80%", marginBottom: "2em", marginTop: "4em" }}
        ></img>
        <Typography
          component="h1"
          variant="overline"
          style={{ fontSize: "1.1em" }}
        >
          Atendimento Memória Viva
        </Typography>
        {showAlert && (
          <Alert
            severity="warning"
            className={classes.alert}
            onClose={() => {
              setShowAlert(false);
            }}
          >
            Usuário ou senha incorretos.
          </Alert>
        )}

        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Usuário"
            name="username"
            autoComplete="username"
            autoFocus
            value={userName}
            onChange={(event) => {
              setUserName(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleClick()}
            disabled={showAlert === undefined}
          >
            Entrar
          </Button>
          <Grid container></Grid>
          {showAlert === undefined && <LinearProgress />}
        </div>
      </div>
    </Container>
  );
}
