import React, { ReactNode } from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import { Button, Grid, IconButton, Modal } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

interface DialogProps {
  dialogTitle?: string;
  primaryButtonText: string;
  onClickPrimaryButton: Function;
  secondaryButtonText?: string;
  onClickSecondaryButton?: Function;
  openDialog: boolean;
  setOpenDialog: Function;
  children: ReactNode;
}

const CustomModal = ({
  dialogTitle,
  openDialog,
  setOpenDialog,
  primaryButtonText,
  onClickPrimaryButton,
  secondaryButtonText,
  onClickSecondaryButton,
  children,
}: DialogProps) => {
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        position: "absolute",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: "none",
        borderRadius: "10px",
        padding: theme.spacing(2, 4, 3),
        outline: "none",
      },
      upload: {
        height: 200,
      },
      comments: {
        marginTop: "45px",
      },
      modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(4),
        position: "relative",
        paddingTop: theme.spacing(1),
      },
      modalTitle: {
        flex: 1,
        textAlign: "center",
        marginRight: 0,
      },
      closeButton: {
        position: "absolute",
        right: -theme.spacing(2),
        top: -theme.spacing(1),
      },
      modalContent: {
        marginBottom: theme.spacing(3),
      },
      modalActions: {
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing(2),
      },
    })
  );

  const [modalStyle] = React.useState(getModalStyle);

  const classes = useStyles();

  return (
    <Modal
      open={openDialog}
      onClose={() => setOpenDialog()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid container style={modalStyle} className={classes.paper}>
        <Grid item xs={12} className={classes.modalHeader}>
          <div className={classes.modalTitle}>
            <ModalWarning>{dialogTitle}</ModalWarning>
          </div>
          <IconButton
            id="close-dialog"
            onClick={() => setOpenDialog(!openDialog)}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12} className={classes.modalContent}>
          {children}
        </Grid>
        <Grid item xs={12} className={classes.modalActions}>
          {onClickSecondaryButton && secondaryButtonText && (
            <Button
              id="dialog-secondary-button"
              onClick={() => onClickSecondaryButton()}
            >
              <ModalTextButton>{secondaryButtonText}</ModalTextButton>
            </Button>
          )}

          <Button
            id="dialog-primary-button"
            variant="contained"
            color="primary"
            onClick={() => onClickPrimaryButton()}
          >
            {primaryButtonText}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

const ModalTextButton = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0e4b82;
`;

const ModalWarning = styled.h1`
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
  color: #0e4b82;
`;

export { CustomModal };
