import axios from "axios";
import { Session } from "../../components/NavRoute/NavRoute";
import { Atendimento } from "../../models/atendimentoResponse";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const getAtendimentos = async (page: number, rowsPerPage: number) => {
  const session = sessionStorage.getItem("iv-crm");
  let token = "";
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.get(
      `/api/memorial?page=${page}&size=${rowsPerPage}`,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const dataResponse: Atendimento = response.data;
    return { res: true, data: dataResponse };
  } catch (error: any) {
    return {
      res: false,
      errorData: error.response?.data?.message || "",
    };
  }
};
