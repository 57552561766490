import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ListIcon from "@material-ui/icons/List";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { useHistory } from "react-router-dom";
import { Session } from "../NavRoute/NavRoute";
const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    list: {
      minWidth: 0,
      marginRight: "16px",
    },
    appBar: {
      marginBottom: "1em",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontSize: "1rem",
      fontWeight: 800,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      padding: theme.spacing(2),
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    listItem: {
      marginBottom: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
);

export default function Appbar() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOut = () => {
    sessionStorage.removeItem("iv-crm");
    history.push("/");
  };

  const checkPermission = (permission: string) => {
    const sessionObj = sessionStorage.getItem("iv-crm");
    if (sessionObj !== null && sessionObj !== '""') {
      const session: Session = JSON.parse(sessionObj);
      if (session.roles.includes("ROLE_" + permission)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="overline"
            noWrap
            className={classes.title}
          >
            Atendimento Memória Viva
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            key="Cadastro de atendimento"
            onClick={() => history.push("/home")}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.list}>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Cadastro de atendimento"} />
          </ListItem>
          <ListItem
            button
            key="Gerenciar atendimentos"
            onClick={() => history.push("/atendimentos")}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.list}>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary={"Gerenciar atendimentos"} />
          </ListItem>

          {checkPermission("ADMIN") && (
            <>
              <ListItem
                button
                key="Cadastrar Usuário"
                onClick={() => history.push("/users/create")}
                className={classes.listItem}
              >
                <ListItemIcon className={classes.list}>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText primary={"Cadastrar Usuário"} />
              </ListItem>
              <ListItem
                button
                key="Gerenciar Usuários"
                onClick={() => history.push("/users/list")}
                className={classes.listItem}
              >
                <ListItemIcon className={classes.list}>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary={"Gerenciar Usuários"} />
              </ListItem>
            </>
          )}

          <ListItem
            button
            key="Sair"
            onClick={() => logOut()}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.list}>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText primary={"Sair"} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
