import React, { useState } from "react";
import {
  Container,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  makeStyles,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SnackBar from "../../components/Snackbar/SnackBar";
import { createUser } from "./services";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.common.white,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  sectionCard: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    width: "100%",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  divider: {
    margin: "1em 0",
  },
  helperText: {
    marginLeft: 0,
  },
  permissionsContainer: {
    display: "flex",
    width: "100%",
  },
  checkboxLabel: {
    marginRight: theme.spacing(4),
  },
}));

export interface UserRequest {
  username: string;
  email: string;
  password: string;
  roles: Array<string>;
  name: string;
}

export default function CreateUser() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCommonUser, setIsCommonUser] = useState(true);
  const [isSnackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setSnackError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleChangeName = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
    setNameError("");
  };

  const handleChangeUserEmail = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setUserEmail(event.target.value as string);
    setEmailError("");
  };

  const handleChangePassword = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPassword(event.target.value as string);
    setPasswordError("");
  };

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setConfirmPassword(event.target.value as string);
    setConfirmPasswordError("");
  };

  const handleChangeIsAdmin = (
    event: React.ChangeEvent<{}>,
    checked: boolean
  ) => {
    setIsAdmin(checked);
  };

  const handleChangeIsCommonUser = (
    event: React.ChangeEvent<{}>,
    checked: boolean
  ) => {
    setIsCommonUser(checked);
  };

  const validateForm = () => {
    let isValid = true;

    if (name === "") {
      setNameError("Preencha o nome");
      isValid = false;
    }
    if (userEmail === "") {
      setEmailError("Preencha o email");
      isValid = false;
    }
    if (password === "") {
      setPasswordError("Preencha a senha");
      isValid = false;
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirme a senha");
      isValid = false;
    }
    if (confirmPassword !== password) {
      setConfirmPasswordError("As senhas devem ser iguais");
      isValid = false;
    }

    if (isValid) {
      sendCreateUserRequest();
    }
  };

  const sendCreateUserRequest = async () => {
    setIsLoading(true);
    try {
      const roles = ["user"];
      if (isAdmin) {
        roles.push("admin");
      }

      const data: UserRequest = {
        username: userEmail,
        email: userEmail,
        password: password,
        roles: roles,
        name: name,
      };

      const response = await createUser(data);
      if (response.res) {
        setSnackMessage("Usuário cadastrado com sucesso");
        setSnackError(false);
        setSnackOpen(true);
        cleanAllFields();
      } else {
        setSnackMessage(
          response.errorData
            ? `Erro ao cadastrar usuário: ${response.errorData}`
            : "Erro ao cadastrar usuário"
        );
        setSnackError(true);
        setSnackOpen(true);
      }
    } catch (error: any) {
      setSnackMessage(
        error.response?.data?.message
          ? `Erro ao cadastrar usuário: ${error.response.data.message}`
          : "Erro ao cadastrar usuário"
      );
      setSnackError(true);
      setSnackOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const cleanAllFields = () => {
    setName("");
    setUserEmail("");
    setPassword("");
    setConfirmPassword("");
    setIsAdmin(false);
    setIsCommonUser(true);
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <PersonAddIcon className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Cadastrar Usuário
        </Typography>

        {isLoading && <LinearProgress className={classes.progress} />}

        <Paper className={classes.sectionCard} elevation={1}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Dados do Usuário
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Nome completo"
                value={name}
                onChange={handleChangeName}
                error={!!nameError}
                helperText={nameError}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email"
                type="email"
                value={userEmail}
                onChange={handleChangeUserEmail}
                error={!!emailError}
                helperText={emailError}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Senha"
                type="password"
                value={password}
                onChange={handleChangePassword}
                error={!!passwordError}
                helperText={passwordError}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Confirmar senha"
                type="password"
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.sectionCard} elevation={1}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Permissões
          </Typography>
          <div className={classes.permissionsContainer}>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  checked={isCommonUser}
                  onChange={handleChangeIsCommonUser}
                  color="primary"
                />
              }
              label="Usuário Comum"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAdmin}
                  onChange={handleChangeIsAdmin}
                  color="primary"
                />
              }
              label="Administrador"
            />
          </div>
        </Paper>

        <Grid container justify="flex-end">
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={validateForm}
              disabled={isLoading}
            >
              {isLoading ? "CADASTRANDO..." : "CADASTRAR"}
            </Button>
          </Grid>
        </Grid>
      </div>
      <SnackBar
        message={snackMessage}
        isOpen={isSnackOpen}
        severity={isSnackError ? "error" : "success"}
        handleClose={() => setSnackOpen(false)}
      />
    </Container>
  );
}
