import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import Appbar from "../Appbar/Appbar";
import jwt_decode from "jwt-decode";

interface NavRouteProps {
  exact: boolean | undefined;
  path: string;
  component: any;
  permission?: string;
}

export interface Session {
  id: number;
  username: string;
  email: string;
  roles: Array<string>;
  accessToken: string;
  loginTime?: number;
}

interface DecodedToken {
  id: number;
  exp: number;
  iat: number;
}

const MAX_SESSION_TIME = 24 * 60 * 60 * 1000; // 24 horas em milissegundos

export const NavRoute = ({
  exact,
  path,
  component: Component,
  permission,
}: NavRouteProps) => {
  const validateToken = () => {
    const session = sessionStorage.getItem("iv-crm");
    if (session !== null && session !== '""') {
      try {
        const token: Session = JSON.parse(session);
        if (!token.accessToken) {
          sessionStorage.removeItem("iv-crm");
          return false;
        }

        // Verifica se já passou 24 horas desde o login
        const loginTime = token.loginTime || 0;
        const currentTime = new Date().getTime();
        if (currentTime - loginTime > MAX_SESSION_TIME) {
          sessionStorage.removeItem("iv-crm");
          return false;
        }

        const decoded_token: DecodedToken = jwt_decode(token.accessToken);
        const currentDate = new Date();

        if (decoded_token.exp * 1000 > currentDate.getTime()) {
          if (permission === undefined) {
            return true;
          }
          return checkPermissions(token, permission);
        } else {
          // Token expirado
          sessionStorage.removeItem("iv-crm");
          return false;
        }
      } catch (error) {
        // Erro ao decodificar token ou token inválido
        sessionStorage.removeItem("iv-crm");
        return false;
      }
    }
    return false;
  };

  const checkPermissions = (session: Session, permission: string) => {
    try {
      return session.roles.includes("ROLE_" + permission);
    } catch {
      return false;
    }
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        validateToken() ? (
          <Fragment>
            <Appbar />
            <Component {...props} />
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
