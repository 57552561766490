import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CPFMask from "../Masks/CPFMask";
import DateMask from "../Masks/DateMask";
import HourMask from "../Masks/HourMask";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(8, 0, 2),
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: "1em 0",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  progress: {
    marginBottom: theme.spacing(2),
  },
  radioGroup: {
    marginTop: theme.spacing(0.5),
  },
  dateTimeSection: {
    marginTop: theme.spacing(1),
  },
  dateTimeGrid: {
    marginTop: theme.spacing(1),
  },
  dateTimeTitle: {
    marginBottom: theme.spacing(1),
  },
  sectionCard: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  errorField: {
    scrollMarginTop: "180px",
  },
}));

interface EditMemorialFormProps {
  formData: any;
  errors: any;
  isLoading: boolean;
  onFieldChange: (field: string, value: any) => void;
  onSubmit: () => void;
}

export const EditMemorialForm: React.FC<EditMemorialFormProps> = ({
  formData,
  errors,
  isLoading,
  onFieldChange,
  onSubmit,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const firstErrorField = document.querySelector('[aria-invalid="true"]');
    if (firstErrorField) {
      setTimeout(() => {
        firstErrorField.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  }, [errors]);

  const getErrorClass = (hasError: boolean) => {
    return hasError ? classes.errorField : "";
  };

  const renderDeceasedSection = () => (
    <Paper className={classes.sectionCard} elevation={1}>
      <Typography variant="h6" className={classes.sectionTitle}>
        Ente querido(a)
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Ativar tributo</FormLabel>
            <RadioGroup
              row
              value={formData.deceased.isTribute}
              onChange={(e) =>
                onFieldChange("deceased.isTribute", e.target.value === "true")
              }
              className={classes.radioGroup}
            >
              <FormControlLabel value={true} control={<Radio />} label="Sim" />
              <FormControlLabel value={false} control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            required
            fullWidth
            label="Nome completo"
            value={formData.deceased.name}
            onChange={(e) => onFieldChange("deceased.name", e.target.value)}
            error={!!errors.deceased?.name}
            helperText={errors.deceased?.name}
            className={getErrorClass(!!errors.deceased?.name)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="CPF"
            value={formData.deceased.cpf}
            onChange={(e) => onFieldChange("deceased.cpf", e.target.value)}
            error={!!errors.deceased?.cpf}
            helperText={errors.deceased?.cpf}
            className={getErrorClass(!!errors.deceased?.cpf)}
            InputProps={{
              inputComponent: CPFMask as any,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Data de nascimento"
            value={formData.deceased.birthday}
            onChange={(e) => onFieldChange("deceased.birthday", e.target.value)}
            error={!!errors.deceased?.birthday}
            helperText={errors.deceased?.birthday}
            className={getErrorClass(!!errors.deceased?.birthday)}
            InputProps={{
              inputComponent: DateMask as any,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Data de falecimento"
            value={formData.deceased.deathday}
            onChange={(e) => onFieldChange("deceased.deathday", e.target.value)}
            error={!!errors.deceased?.deathday}
            helperText={errors.deceased?.deathday}
            className={getErrorClass(!!errors.deceased?.deathday)}
            InputProps={{
              inputComponent: DateMask as any,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descrição"
            multiline
            rows={4}
            value={formData.deceased.description}
            onChange={(e) =>
              onFieldChange("deceased.description", e.target.value)
            }
            inputProps={{ maxLength: 2000 }}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  const renderFuneralSection = () => (
    <Paper className={classes.sectionCard} elevation={1}>
      <Typography variant="h6" className={classes.sectionTitle}>
        Velório
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="Local"
            value={formData.funeral.location}
            onChange={(e) => onFieldChange("funeral.location", e.target.value)}
            error={!!errors.funeral?.location}
            helperText={errors.funeral?.location}
            className={getErrorClass(!!errors.funeral?.location)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="Cidade"
            value={formData.funeral.city}
            onChange={(e) => onFieldChange("funeral.city", e.target.value)}
            error={!!errors.funeral?.city}
            helperText={errors.funeral?.city}
            className={getErrorClass(!!errors.funeral?.city)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="Estado"
            value={formData.funeral.state}
            onChange={(e) => onFieldChange("funeral.state", e.target.value)}
            error={!!errors.funeral?.state}
            helperText={errors.funeral?.state}
            className={getErrorClass(!!errors.funeral?.state)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              Possui data e horário definido?
            </FormLabel>
            <RadioGroup
              row
              value={formData.funeral.hasTime}
              onChange={(e) =>
                onFieldChange("funeral.hasTime", e.target.value === "true")
              }
              className={classes.radioGroup}
            >
              <FormControlLabel value={true} control={<Radio />} label="Sim" />
              <FormControlLabel value={false} control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
          {formData.funeral.hasTime && (
            <div className={classes.dateTimeSection}>
              <Typography variant="subtitle1" className={classes.dateTimeTitle}>
                Data e Horário
              </Typography>
              <Grid container spacing={2} className={classes.dateTimeGrid}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Data de Início"
                    value={formData.funeral.startDate}
                    onChange={(e) =>
                      onFieldChange("funeral.startDate", e.target.value)
                    }
                    error={!!errors.funeral?.startDate}
                    helperText={errors.funeral?.startDate}
                    className={getErrorClass(!!errors.funeral?.startDate)}
                    InputProps={{
                      inputComponent: DateMask as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Hora de Início"
                    value={formData.funeral.startTime}
                    onChange={(e) =>
                      onFieldChange("funeral.startTime", e.target.value)
                    }
                    error={!!errors.funeral?.startTime}
                    helperText={errors.funeral?.startTime}
                    className={getErrorClass(!!errors.funeral?.startTime)}
                    InputProps={{
                      inputComponent: HourMask as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Data de Fim"
                    value={formData.funeral.endDate}
                    onChange={(e) =>
                      onFieldChange("funeral.endDate", e.target.value)
                    }
                    error={!!errors.funeral?.endDate}
                    helperText={errors.funeral?.endDate}
                    className={getErrorClass(!!errors.funeral?.endDate)}
                    InputProps={{
                      inputComponent: DateMask as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Hora de Fim"
                    value={formData.funeral.endTime}
                    onChange={(e) =>
                      onFieldChange("funeral.endTime", e.target.value)
                    }
                    error={!!errors.funeral?.endTime}
                    helperText={errors.funeral?.endTime}
                    className={getErrorClass(!!errors.funeral?.endTime)}
                    InputProps={{
                      inputComponent: HourMask as any,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );

  const renderBurialSection = () => (
    <Paper className={classes.sectionCard} elevation={1}>
      <Typography variant="h6" className={classes.sectionTitle}>
        Sepultamento
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Cremação</FormLabel>
            <RadioGroup
              row
              value={formData.deceased.isCremation}
              onChange={(e) =>
                onFieldChange("deceased.isCremation", e.target.value === "true")
              }
              className={classes.radioGroup}
            >
              <FormControlLabel value={true} control={<Radio />} label="Sim" />
              <FormControlLabel value={false} control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="Local"
            value={formData.burial.location}
            onChange={(e) => onFieldChange("burial.location", e.target.value)}
            error={!!errors.burial?.location}
            helperText={errors.burial?.location}
            className={getErrorClass(!!errors.burial?.location)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="Cidade"
            value={formData.burial.city}
            onChange={(e) => onFieldChange("burial.city", e.target.value)}
            error={!!errors.burial?.city}
            helperText={errors.burial?.city}
            className={getErrorClass(!!errors.burial?.city)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="Estado"
            value={formData.burial.state}
            onChange={(e) => onFieldChange("burial.state", e.target.value)}
            error={!!errors.burial?.state}
            helperText={errors.burial?.state}
            className={getErrorClass(!!errors.burial?.state)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              Possui data e horário definido?
            </FormLabel>
            <RadioGroup
              row
              value={formData.burial.hasTime}
              onChange={(e) =>
                onFieldChange("burial.hasTime", e.target.value === "true")
              }
              className={classes.radioGroup}
            >
              <FormControlLabel value={true} control={<Radio />} label="Sim" />
              <FormControlLabel value={false} control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
          {formData.burial.hasTime && (
            <div className={classes.dateTimeSection}>
              <Typography variant="subtitle1" className={classes.dateTimeTitle}>
                Data e Horário
              </Typography>
              <Grid container spacing={2} className={classes.dateTimeGrid}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Data de Início"
                    value={formData.burial.startDate}
                    onChange={(e) =>
                      onFieldChange("burial.startDate", e.target.value)
                    }
                    error={!!errors.burial?.startDate}
                    helperText={errors.burial?.startDate}
                    className={getErrorClass(!!errors.burial?.startDate)}
                    InputProps={{
                      inputComponent: DateMask as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Hora de Início"
                    value={formData.burial.startTime}
                    onChange={(e) =>
                      onFieldChange("burial.startTime", e.target.value)
                    }
                    error={!!errors.burial?.startTime}
                    helperText={errors.burial?.startTime}
                    className={getErrorClass(!!errors.burial?.startTime)}
                    InputProps={{
                      inputComponent: HourMask as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Data de Fim"
                    value={formData.burial.endDate}
                    onChange={(e) =>
                      onFieldChange("burial.endDate", e.target.value)
                    }
                    error={!!errors.burial?.endDate}
                    helperText={errors.burial?.endDate}
                    className={getErrorClass(!!errors.burial?.endDate)}
                    InputProps={{
                      inputComponent: DateMask as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Hora de Fim"
                    value={formData.burial.endTime}
                    onChange={(e) =>
                      onFieldChange("burial.endTime", e.target.value)
                    }
                    error={!!errors.burial?.endTime}
                    helperText={errors.burial?.endTime}
                    className={getErrorClass(!!errors.burial?.endTime)}
                    InputProps={{
                      inputComponent: HourMask as any,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {isLoading && <LinearProgress className={classes.progress} />}
      {renderDeceasedSection()}
      {renderFuneralSection()}
      {renderBurialSection()}
      <Grid container justify="flex-end">
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            disabled={isLoading}
          >
            {isLoading ? "EDITANDO..." : "EDITAR"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
