import { useState } from "react";
import { HomeFormData, FormErrors } from "../models/HomeForm";
import { isValidCPF } from "../utils";

const initialFormData: HomeFormData = {
  relative: {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
  deceased: {
    name: "",
    cpf: "",
    birthday: "",
    deathday: "",
    description: "",
    isTribute: false,
    isCremation: false,
  },
  funeral: {
    location: "",
    city: "",
    state: "",
    hasTime: false,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  },
  burial: {
    location: "",
    city: "",
    state: "",
    hasTime: false,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  },
};

const initialErrors: FormErrors = {
  relative: {},
  deceased: {},
  funeral: {},
  burial: {},
};

export const useHomeForm = () => {
  const [formData, setFormData] = useState<HomeFormData>(initialFormData);
  const [errors, setErrors] = useState<FormErrors>(initialErrors);
  const [isLoading, setIsLoading] = useState(false);

  const isDateInFuture = (dateStr: string) => {
    if (!dateStr) return false;
    const [day, month, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date > today;
  };

  const isEndDateTimeBeforeStartDateTime = (
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  ) => {
    if (!startDate || !startTime || !endDate || !endTime) return false;

    const [startDay, startMonth, startYear] = startDate.split("/").map(Number);
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const startDateTime = new Date(
      startYear,
      startMonth - 1,
      startDay,
      startHour,
      startMinute
    );

    const [endDay, endMonth, endYear] = endDate.split("/").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
    const endDateTime = new Date(
      endYear,
      endMonth - 1,
      endDay,
      endHour,
      endMinute
    );

    return endDateTime <= startDateTime;
  };

  const updateField = (path: string, value: any) => {
    setFormData((prev) => {
      const newData = { ...prev };
      const keys = path.split(".");
      let current: any = newData;

      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }

      const lastKey = keys[keys.length - 1];
      current[lastKey] = value;

      // Resetar campos de data e hora quando hasTime é alterado para false
      if (lastKey === "hasTime" && value === false) {
        current.startDate = "";
        current.startTime = "";
        current.endDate = "";
        current.endTime = "";
      }

      return newData;
    });

    // Limpar erro do campo quando ele é alterado
    setErrors((prev) => {
      const newErrors = { ...prev };
      const keys = path.split(".");
      let current: any = newErrors;

      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
          current[keys[i]] = {};
        }
        current = current[keys[i]];
      }

      const lastKey = keys[keys.length - 1];
      delete current[lastKey];

      return newErrors;
    });
  };

  const validateForm = () => {
    const newErrors: FormErrors = {
      relative: {},
      deceased: {},
      funeral: {},
      burial: {},
    };

    // Validação do responsável
    if (!formData.relative.name.trim()) {
      newErrors.relative.name = "Preencha o nome do responsável";
    }
    if (!formData.relative.email.trim()) {
      newErrors.relative.email = "Preencha o email do responsável";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.relative.email)) {
      newErrors.relative.email = "Email inválido";
    }
    if (!formData.relative.password) {
      newErrors.relative.password = "Preencha a senha";
    }
    if (!formData.relative.confirmPassword) {
      newErrors.relative.confirmPassword = "Confirme a senha";
    }
    if (formData.relative.password !== formData.relative.confirmPassword) {
      newErrors.relative.confirmPassword = "As senhas não coincidem";
    }

    // Validação do falecido
    if (!formData.deceased.name.trim()) {
      newErrors.deceased.name = "Preencha o nome";
    }
    if (!formData.deceased.cpf.trim()) {
      newErrors.deceased.cpf = "Preencha o CPF";
    } else if (!isValidCPF(formData.deceased.cpf)) {
      newErrors.deceased.cpf = "CPF inválido";
    }
    if (!formData.deceased.birthday) {
      newErrors.deceased.birthday = "Preencha a data de nascimento";
    }
    if (!formData.deceased.deathday) {
      newErrors.deceased.deathday = "Preencha a data de falecimento";
    }

    // Validação da data de falecimento no futuro
    if (
      formData.deceased.deathday &&
      isDateInFuture(formData.deceased.deathday)
    ) {
      newErrors.deceased.deathday =
        "A data de falecimento não pode ser no futuro";
    }

    // Validação do velório
    if (!formData.funeral.location.trim()) {
      newErrors.funeral.location = "Preencha o local";
    }
    if (!formData.funeral.state.trim()) {
      newErrors.funeral.state = "Preencha o estado";
    }
    if (!formData.funeral.city.trim()) {
      newErrors.funeral.city = "Preencha a cidade";
    }

    if (formData.funeral.hasTime) {
      if (!formData.funeral.startDate) {
        newErrors.funeral.startDate = "Preencha a data de início";
      }
      if (!formData.funeral.startTime) {
        newErrors.funeral.startTime = "Preencha a hora de início";
      }
      if (!formData.funeral.endDate) {
        newErrors.funeral.endDate = "Preencha a data de fim";
      }
      if (!formData.funeral.endTime) {
        newErrors.funeral.endTime = "Preencha a hora de fim";
      }

      // Validação de data/hora início e fim do velório
      if (
        formData.funeral.startDate &&
        formData.funeral.startTime &&
        formData.funeral.endDate &&
        formData.funeral.endTime &&
        isEndDateTimeBeforeStartDateTime(
          formData.funeral.startDate,
          formData.funeral.startTime,
          formData.funeral.endDate,
          formData.funeral.endTime
        )
      ) {
        newErrors.funeral.endDate =
          "A data/hora de fim deve ser posterior à data/hora de início";
        newErrors.funeral.endTime =
          "A data/hora de fim deve ser posterior à data/hora de início";
      }
    }

    // Validação do sepultamento
    if (!formData.burial.location.trim()) {
      newErrors.burial.location = "Preencha o local";
    }
    if (!formData.burial.state.trim()) {
      newErrors.burial.state = "Preencha o estado";
    }
    if (!formData.burial.city.trim()) {
      newErrors.burial.city = "Preencha a cidade";
    }

    if (formData.burial.hasTime) {
      if (!formData.burial.startDate) {
        newErrors.burial.startDate = "Preencha a data de início";
      }
      if (!formData.burial.startTime) {
        newErrors.burial.startTime = "Preencha a hora de início";
      }
      if (!formData.burial.endDate) {
        newErrors.burial.endDate = "Preencha a data de fim";
      }
      if (!formData.burial.endTime) {
        newErrors.burial.endTime = "Preencha a hora de fim";
      }

      // Validação de data/hora início e fim do sepultamento
      if (
        formData.burial.startDate &&
        formData.burial.startTime &&
        formData.burial.endDate &&
        formData.burial.endTime &&
        isEndDateTimeBeforeStartDateTime(
          formData.burial.startDate,
          formData.burial.startTime,
          formData.burial.endDate,
          formData.burial.endTime
        )
      ) {
        newErrors.burial.endDate =
          "A data/hora de fim deve ser posterior à data/hora de início";
        newErrors.burial.endTime =
          "A data/hora de fim deve ser posterior à data/hora de início";
      }
    }

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some(
      (section) => Object.keys(section).length > 0
    );

    return !hasErrors;
  };

  const resetForm = () => {
    // setFormData(initialFormData);
    // setErrors(initialErrors);
  };

  return {
    formData,
    errors,
    isLoading,
    setIsLoading,
    updateField,
    validateForm,
    resetForm,
  };
};
