import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface CustomizedSnackbarsProps {
  message: string;
  isOpen: boolean;
  severity: "success" | "error";
  handleClose: () => void;
}

export default function SnackBar({
  message,
  isOpen,
  severity,
  handleClose,
}: CustomizedSnackbarsProps) {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
