import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  badge: {
    position: "fixed",
    padding: "20px",
    top: theme.spacing(1.5),
    right: theme.spacing(2),
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
    "& .MuiChip-label": {
      fontSize: "0.75rem",
    },
  },
}));

export const EnvironmentBadge: React.FC = () => {
  const classes = useStyles();
  const environment = process.env.REACT_APP_ENVIRONMENT;

  if (environment === "prod") return null;

  const getEnvironmentLabel = () => {
    switch (environment) {
      case "homolog":
        return "HOMOLOGAÇÃO";
      case "dev":
        return "DESENVOLVIMENTO";
      default:
        return environment;
    }
  };

  return (
    <Chip
      label={getEnvironmentLabel()}
      className={classes.badge}
      size="small"
    />
  );
};
