import React, { useEffect, useState } from "react";
import {
  Container,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CssBaseline from "@material-ui/core/CssBaseline";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { getAtendimentos } from "./services";
import { Memorials } from "../../models/atendimentoResponse";
import { useHistory } from "react-router-dom";
import SnackBar from "../../components/Snackbar/SnackBar";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  table: {
    width: "100%",
  },
  progress: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  noData: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.selected,
      },
    },
  })
)(TableRow);

export default function ListMemorials() {
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [memorialsList, setMemorialsList] = useState<Memorials[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setSnackError] = useState(false);

  const loadMemorials = async () => {
    setIsLoading(true);
    try {
      const response = await getAtendimentos(page, rowsPerPage);
      if (response.data !== undefined) {
        setMemorialsList(response.data?.memoriais);
        setTotal(response.data?.totalItems);
      }
    } catch (error: any) {
      setSnackMessage(
        error.response?.data?.message
          ? `Erro ao carregar lista de memoriais: ${error.response.data.message}`
          : "Erro ao carregar lista de memoriais"
      );
      setSnackError(true);
      setSnackOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMemorials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (id: number) => {
    history.push(`/atendimentos/${id}`);
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <PeopleAltIcon className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Lista de Atendimentos
        </Typography>

        {isLoading && <LinearProgress className={classes.progress} />}

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Nome</StyledTableCell>
                <StyledTableCell align="center" width="100px">
                  Ações
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memorialsList.length > 0 ? (
                memorialsList.map((memorial) => (
                  <StyledTableRow key={memorial.id}>
                    <StyledTableCell align="left">
                      {memorial.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        onClick={() => handleEditClick(memorial.id)}
                        color="primary"
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} className={classes.noData}>
                    {isLoading
                      ? "Carregando memoriais..."
                      : "Nenhum memorial encontrado"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage="Itens por página:"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        </TableContainer>
      </div>
      <SnackBar
        message={snackMessage}
        isOpen={isSnackOpen}
        severity={isSnackError ? "error" : "success"}
        handleClose={() => setSnackOpen(false)}
      />
    </Container>
  );
}
