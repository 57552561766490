import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
import Badge from "@material-ui/core/Badge";
import SnackBar from "../../components/Snackbar/SnackBar";
import { createAtendimento, createMemorial, getToken } from "./services";
import { CustomModal } from "../../components/Modal/CustomModal";
import { CreateMemorialResponse } from "../../models/createMemorialResponse";
import { Alert } from "@material-ui/lab";
import { AtendimentoRequest } from "../../models/createAtendimentoRequest";
import { MemorialRequest } from "../../models/memorialRequest";
import { formatToRecifeTimezone } from "../EditMemorial/services";
import { HomeForm } from "../../components/HomeForm/HomeForm";
import { useHomeForm } from "../../hooks/useHomeForm";
import { QRCodeCanvas } from "qrcode.react";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  alert: {
    marginBottom: "1em",
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  linkText: {
    flex: 1,
    margin: 0,
  },
  copyBadge: {
    cursor: "pointer",
    padding: "4px 12px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  qrCodeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  downloadButton: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function Home() {
  const classes = useStyles();
  const [token, setToken] = useState<string>("");
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [memorialData, setMemorialData] = useState<CreateMemorialResponse>();
  const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isSnackError, setSnackError] = useState<boolean>(false);
  const qrCodeRef = useRef<HTMLCanvasElement>(null);

  const {
    formData,
    errors,
    isLoading,
    setIsLoading,
    updateField,
    validateForm,
    resetForm,
  } = useHomeForm();

  const sendGetTokenRequest = async () => {
    let getTokenRequest = await getToken();
    if (getTokenRequest.res && getTokenRequest.data.token !== undefined) {
      setToken("Bearer " + getTokenRequest.data.token);
    }
  };

  const formatDate = (dateString: string) => {
    var dateComponents = dateString.split("/");
    var day = parseInt(dateComponents[0]);
    var month = parseInt(dateComponents[1]);
    var year = parseInt(dateComponents[2]);

    const dateFormatted = new Date(year, month - 1, day)
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    return dateFormatted;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        await sendCreateAtendimentoRequest();
      } catch (error) {
        console.error("Erro ao criar memorial:", error);
      }
    }
  };

  const sendCreateAtendimentoRequest = async () => {
    setIsLoading(true);

    const data: AtendimentoRequest = {
      falecidomemorialativo: 1,
      falecidocpf: formData.deceased.cpf,
      falecidonome: formData.deceased.name,
      falecidodatanascimento: formatDate(formData.deceased.birthday),
      falecidodataobito: formatDate(formData.deceased.deathday),
      memorialresponsavelnome: formData.relative.name,
      memorialresponsavelemail: formData.relative.email,
    };

    const response = await createAtendimento(data);
    if (response.data !== undefined && response.res) {
      await sendCreateMemorialRequest(response.data.falecidoid);
    } else {
      setSnackError(true);
      setSnackOpen(true);
      setSnackMessage(
        response.errorData
          ? `Ocorreu um erro ao cadastrar o memorial: ${response.errorData}`
          : "Ocorreu um erro ao cadastrar o memorial"
      );
      setIsLoading(false);
    }
  };

  const sendCreateMemorialRequest = async (falecidoApiId: number) => {
    await sendGetTokenRequest();

    const data: MemorialRequest = {
      token: token,
      data: {
        falecidoId: falecidoApiId,
        cpf: formData.deceased.cpf,
        name: formData.deceased.name,
        description: formData.deceased.description,
        birthdayDateText: formatDate(formData.deceased.birthday),
        deathDateText: formatDate(formData.deceased.deathday),
        funeralLocation: formData.funeral.location,
        funeralCity: formData.funeral.city,
        funeralState: formData.funeral.state,
        funeralStartDateText:
          formData.funeral.startDate !== "" || formData.funeral.startTime !== ""
            ? formatToRecifeTimezone(
                formData.funeral.startDate,
                formData.funeral.startTime
              )
            : "",
        funeralEndDateText:
          formData.funeral.endDate !== "" || formData.funeral.endTime !== ""
            ? formatToRecifeTimezone(
                formData.funeral.endDate,
                formData.funeral.endTime
              )
            : "",
        burialLocation: formData.burial.location,
        burialCity: formData.burial.city,
        burialState: formData.burial.state,
        burialStartDateText:
          formData.burial.startDate !== "" || formData.burial.startTime !== ""
            ? formatToRecifeTimezone(
                formData.burial.startDate,
                formData.burial.startTime
              )
            : "",
        burialEndDateText:
          formData.burial.endDate !== "" || formData.burial.endTime !== ""
            ? formatToRecifeTimezone(
                formData.burial.endDate,
                formData.burial.endTime
              )
            : "",
        activeTribute: formData.deceased.isTribute,
        cremation: formData.deceased.isCremation,
        hasBurialTime: formData.burial.hasTime ? 1 : 0,
        hasFuneralTime: formData.funeral.hasTime ? 1 : 0,
        relative: {
          name: formData.relative.name,
          email: formData.relative.email,
          password: formData.relative.password,
        },
      },
    };

    const createMemorialRequest = await createMemorial(data);

    if (createMemorialRequest.data !== undefined) {
      setMemorialData(createMemorialRequest.data);
    }

    if (createMemorialRequest.res && createMemorialRequest.data !== undefined) {
      setSnackError(false);
      setSnackOpen(true);
      setSnackMessage("Memorial criado com sucesso.");
      setOpenDetailModal(true);
      resetForm();
    } else {
      setSnackError(true);
      setSnackOpen(true);
      setSnackMessage(
        createMemorialRequest.errorData
          ? `Ocorreu um erro ao cadastrar o memorial: ${createMemorialRequest.errorData}`
          : "Ocorreu um erro ao cadastrar o memorial"
      );
    }
    setIsLoading(false);
  };

  const handleCopyLink = () => {
    const memorialLink = `${process.env.REACT_APP_MEMORIA_VIVA_URL}/perfil/${memorialData?.id}`;
    navigator.clipboard.writeText(memorialLink);
    setSnackMessage("Link copiado com sucesso!");
    setSnackError(false);
    setSnackOpen(true);
  };

  const handleDownloadQRCode = () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current;
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");

      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;

      if (tempCtx) {
        tempCtx.fillStyle = "white";
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
        tempCtx.drawImage(canvas, 0, 0);

        const link = document.createElement("a");
        link.download = "memorial-qrcode.png";
        // Aumentando a qualidade da imagem PNG
        link.href = tempCanvas.toDataURL("image/png", 1.0);
        link.click();
      }
    }
  };

  const handleCloseModal = () => {
    setOpenDetailModal(false);
    resetForm();
    setMemorialData(undefined);
    window.location.reload();
  };

  useEffect(() => {
    sendGetTokenRequest();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <PeopleAltIcon />
        <Typography component="h1" variant="h5">
          Cadastro de Atendimento
        </Typography>
        <CustomModal
          dialogTitle="O Memorial foi criado com sucesso"
          openDialog={openDetailModal}
          setOpenDialog={handleCloseModal}
          primaryButtonText="Fechar"
          onClickPrimaryButton={handleCloseModal}
        >
          <Alert severity="info" className={classes.alert}>
            O memorial foi criado e está pronto pare ser compartilhado. O
            responsável deve adicionar um foto de perfil para que ele apareça na
            página inicial do {process.env.REACT_APP_MEMORIA_VIVA_URL}.
          </Alert>
          <Typography variant="body2">
            <b>Responsável:</b> {memorialData?.relative.name}
          </Typography>

          <Typography variant="body2">
            <b>Email:</b> {memorialData?.relative.email}
          </Typography>
          <div className={classes.linkContainer}>
            <Typography variant="body2" className={classes.linkText}>
              <b>Link do memorial:</b>{" "}
              <div style={{ display: "flex", alignItems: "center" }}>
                {`${process.env.REACT_APP_MEMORIA_VIVA_URL}/perfil/${memorialData?.id}`}
                <Badge
                  className={classes.copyBadge}
                  onClick={handleCopyLink}
                  title="Copiar link"
                  style={{ marginLeft: "10px" }}
                >
                  Copiar
                  <FileCopyIcon fontSize="small" />
                </Badge>
              </div>
            </Typography>
          </div>

          <div className={classes.qrCodeContainer}>
            <QRCodeCanvas
              ref={qrCodeRef}
              value={`${process.env.REACT_APP_MEMORIA_VIVA_URL}/perfil/${memorialData?.id}`}
              size={2000}
              level="H"
              includeMargin={true}
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Button
              variant="contained"
              className={classes.downloadButton}
              onClick={handleDownloadQRCode}
              startIcon={<GetAppIcon />}
            >
              Baixar QR Code
            </Button>
          </div>
        </CustomModal>
        <HomeForm
          formData={formData}
          errors={errors}
          isLoading={isLoading}
          onFieldChange={updateField}
          onSubmit={handleSubmit}
        />
      </div>
      <SnackBar
        message={snackMessage}
        isOpen={isSnackOpen}
        severity={isSnackError ? "error" : "success"}
        handleClose={() => setSnackOpen(false)}
      />
    </Container>
  );
}
