import { useState, useEffect } from "react";
import { getAtendimentos } from "../pages/EditMemorial/services";

interface EditMemorialFormData {
  deceased: {
    name: string;
    cpf: string;
    birthday: string;
    deathday: string;
    description: string;
    isTribute: boolean;
    isCremation: boolean;
  };
  funeral: {
    location: string;
    city: string;
    state: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    hasTime: boolean;
  };
  burial: {
    location: string;
    city: string;
    state: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    hasTime: boolean;
  };
}

interface EditMemorialFormErrors {
  deceased: {
    name?: string;
    cpf?: string;
    birthday?: string;
    deathday?: string;
  };
}

export const useEditMemorialForm = (id?: string) => {
  const [formData, setFormData] = useState<EditMemorialFormData>({
    deceased: {
      name: "",
      cpf: "",
      birthday: "",
      deathday: "",
      description: "",
      isTribute: true,
      isCremation: false,
    },
    funeral: {
      location: "",
      city: "",
      state: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      hasTime: true,
    },
    burial: {
      location: "",
      city: "",
      state: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      hasTime: true,
    },
  });

  const [errors, setErrors] = useState<EditMemorialFormErrors>({
    deceased: {},
  });

  const [isLoading, setIsLoading] = useState(false);

  const updateField = (field: string, value: any) => {
    setFormData((prev) => {
      const [section, fieldName] = field.split(".");
      return {
        ...prev,
        [section]: {
          ...prev[section as keyof EditMemorialFormData],
          [fieldName]: value,
        },
      };
    });
  };

  const validateForm = () => {
    const newErrors: EditMemorialFormErrors = {
      deceased: {},
    };

    if (!formData.deceased.name) {
      newErrors.deceased.name = "Preencha o nome";
    }
    if (!formData.deceased.cpf) {
      newErrors.deceased.cpf = "Preencha o CPF";
    }
    if (!formData.deceased.birthday) {
      newErrors.deceased.birthday = "Preencha a data de nascimento";
    }
    if (!formData.deceased.deathday) {
      newErrors.deceased.deathday = "Preencha a data de falecimento";
    }

    setErrors(newErrors);
    return Object.keys(newErrors.deceased).length === 0;
  };

  const loadMemorialData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const response = await getAtendimentos(parseInt(id));
        if (response.data?.memoriais[0]) {
          const memorial = response.data.memoriais[0];
          setFormData({
            deceased: {
              name: memorial.name,
              cpf: memorial.cpf,
              birthday: memorial.birthday_date
                ? new Date(memorial.birthday_date).toLocaleDateString("pt-BR")
                : "",
              deathday: memorial.death_date
                ? new Date(memorial.death_date).toLocaleDateString("pt-BR")
                : "",
              description: memorial.description || "",
              isTribute: memorial.active_tribute === 1,
              isCremation: memorial.cremation === 1,
            },
            funeral: {
              location: memorial.funeral_location || "",
              city: memorial.funeral_city || "",
              state: memorial.funeral_state || "",
              startDate: memorial.funeral_start_date
                ? new Date(memorial.funeral_start_date).toLocaleDateString(
                    "pt-BR"
                  )
                : "",
              startTime: memorial.funeral_start_date
                ? new Date(memorial.funeral_start_date).toLocaleTimeString(
                    "pt-BR",
                    { hour: "2-digit", minute: "2-digit" }
                  )
                : "",
              endDate: memorial.funeral_end_date
                ? new Date(memorial.funeral_end_date).toLocaleDateString(
                    "pt-BR"
                  )
                : "",
              endTime: memorial.funeral_end_date
                ? new Date(memorial.funeral_end_date).toLocaleTimeString(
                    "pt-BR",
                    { hour: "2-digit", minute: "2-digit" }
                  )
                : "",
              hasTime: memorial.has_funeral_time === 1,
            },
            burial: {
              location: memorial.burial_location || "",
              city: memorial.burial_city || "",
              state: memorial.burial_state || "",
              startDate: memorial.burial_start_date
                ? new Date(memorial.burial_start_date).toLocaleDateString(
                    "pt-BR"
                  )
                : "",
              startTime: memorial.burial_start_date
                ? new Date(memorial.burial_start_date).toLocaleTimeString(
                    "pt-BR",
                    { hour: "2-digit", minute: "2-digit" }
                  )
                : "",
              endDate: memorial.burial_end_date
                ? new Date(memorial.burial_end_date).toLocaleDateString("pt-BR")
                : "",
              endTime: memorial.burial_end_date
                ? new Date(memorial.burial_end_date).toLocaleTimeString(
                    "pt-BR",
                    { hour: "2-digit", minute: "2-digit" }
                  )
                : "",
              hasTime: memorial.has_burial_time === 1,
            },
          });
        }
      } catch (error) {
        console.error("Erro ao carregar dados do memorial:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadMemorialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    formData,
    errors,
    isLoading,
    setIsLoading,
    updateField,
    validateForm,
  };
};
